<template>
  <div>
    <b-card>
      <!---- search employees ----->
      <b-form-group label="Search Employees">
        <b-form-input
          v-model="search"
          placeholder="Wyszukaj pracownika"
          @keyup="searchEmployees"
        />
      </b-form-group>
    </b-card>

    <b-card
      v-for="employee in employees"
      :key="employee"
    >
      <div class="employee">
        <div>
          <BImg
            :src="getImageUrlFromFileName(employee.image)"
            alt=""
            style="width: 100px; height: 100px;"
          />
          <span class="employee-info">
            {{ employee.user.name }}
            <span class="employee-column">
              |
            </span>
          </span>
          <span class="employee-info">
            {{ employee.user.email }}
            <span class="employee-column">
              |
            </span>
          </span>
          <span class="employee-info">
            {{ employee.user.phone_number }}
            <span class="employee-column">
              |
            </span>
          </span>
        </div>
        <div>
          <router-link
            :to="'/Pracownicy/' + employee.id "
            class="btn btn-primary"
          >
            Podgląd
          </router-link>
        </div>
      </div>
    </b-card>
    <router-link :to="'/Pracownicy/dodaj'">
      <button
        class="btn btn-success submit"
        @click="submit()"
      >
        Dodaj nowego pracownika
      </button>
    </router-link>
  </div>
</template>

<script>
import {
  BCard, BCardText, BForm, BFormInput, BImg,
} from 'bootstrap-vue'
import swal from 'sweetalert2'
import axios from 'axios'

export default {
  components: {
    BCard,
    BCardText,
    BForm,
    BFormInput,
    BImg,
  },
  data() {
    return {
      employees: [],
      search: '',
      allEmployees: '',
    }
  },
  mounted() {
    document.title = 'Lista pracowników - Pickmode'

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }

    // get root api url from env file
    const apiUrl = process.env.VUE_APP_API_URL
    axios.get(`${apiUrl}employee`, config)
      .then(response => {
        this.employees = response.data

        this.employees.forEach(employee => {
          if (employee.user.email.length > 15) {
            employee.user.email = `${employee.user.email.substring(0, 15)}...`
          }
          if (employee.user.name.length > 15) {
            employee.user.name = `${employee.user.name.substring(0, 15)}...`
          }
        })

        this.allEmployees = response.data
      })
      .catch(error => {
        swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      })
  },
  methods: {
    searchEmployees() {
      this.employees = this.allEmployees.filter(employee => employee.user.name.toLowerCase().includes(this.search.toLowerCase()))
    },
    getImageUrlFromFileName(image) {
      return `${process.env.VUE_APP_API_URL.substring(0, process.env.VUE_APP_API_URL.length - 4)}uploads/profile_images/${image}`
    },
  },
}
</script>

<style lang="scss">
.employee {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.eye {
  margin-right: 10px;
  cursor: pointer;
}
.submit {
      width: 100%;
    height: 40px;
    border: 1px solid rgba(204, 204, 204, 0.344);
    border-radius: 5px;
    background: rgba(204, 204, 204, 0.468);
    margin-top: 10px;
}
/*mobile view*/
@media (max-width: 768px) {
  .employee-info {
    margin-bottom: 10px;
    display: block;
  }
  .employee-column {
    display: none;
  }
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>
